import React from "react";

import asyncComponent from "./Components/AsyncComponent";
import { useLocation, Route, Switch } from "react-router-dom";

const AsyncFormPage = asyncComponent(() => import("./Pages/FormPage/Index"));
const AsyncHeaderMenu = asyncComponent(() =>
  import("./Components/Menus/HeaderMenu")
);

// import ReactGA from "react-ga";
// function initializeReactGA() {
//   ReactGA.initialize("UA-154707070-1");
//   ReactGA.pageview("/");
// }

function App() {
  //   initializeReactGA();
  var location = useLocation().pathname;
  var isPublic = location.split("/")[1] === "public";

  return (
    <div className="App">
      {isPublic ? (
        <Switch>
          <Route
            path={"/public/forms/:id"}
            children={({ match }) => <AsyncFormPage match={match} />}
          />
        </Switch>
      ) : (
        <AsyncHeaderMenu />
      )}
      <script src="http://localhost:8097"></script>
    </div>
  );
}

export default App;
